import React from 'react';
import { Button } from '@audi/audi-ui-react-v2';
import { VehicleBasic, useServicesContext, getRelatedProduct } from '@oneaudi/vtp-shared';
import { useI18n } from '@oneaudi/i18n-context';
import { trackCTAClick } from './tracking';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';

export interface DealerProps {
  vehicle: VehicleBasic;
  isButton?: boolean;
}

const DealerContact: React.FC<DealerProps> = ({ vehicle, isButton = false }) => {
  const { getConfiguration } = useServicesContext();
  const ecomShowContact = getConfiguration()!.scopes?.showEcomContact; // Hide phone number and show contact link if car is available over ecom
  const showHotline =
    getConfiguration()?.scopes?.showFreeHotline &&
    vehicle.type === 'U' &&
    vehicle.nationWideSelling;
  const dealerPhoneLabel = useI18n({ id: 'nemo.ui.sc.details.dealer-phone', defaultMessage: '' });
  let isvPhoneLabel = useI18n({
    id: 'nemo.ui.sc.details.dealer.investor-shared-vehicle.phone-number',
    defaultMessage: '',
  });
  const isvLabel1 = useI18n({
    defaultMessage: '(phone suffix)',
    id: 'nemo.ui.sc.details.dealer.investor-shared-vehicle.phone-label1',
  });
  const isvLabel2 = useI18n({
    defaultMessage: '(phone suffix)',
    id: 'nemo.ui.sc.details.dealer.investor-shared-vehicle.phone-label2',
  });
  const hotlineLabel = useI18n({
    id: 'nemo.ui.sc.hotline.free.label',
    defaultMessage: '(free of charge)',
  });
  const trackingService = useServicesContext().getAdditionalService(
    'audi-tracking-service'
  ) as TrackingServiceV2;
  const relatedProduct = getRelatedProduct(vehicle);

  let phoneNumbersEl = null;

  if (
    vehicle.dealer?.phoneNumbers &&
    vehicle.dealer.phoneNumbers.length > 0 &&
    !(ecomShowContact && vehicle.buyableOnline)
  ) {
    const phoneNumbers = vehicle.dealer.phoneNumbers.filter(
      (phoneNumber) => phoneNumber.type === 'phone' && phoneNumber.number
    );

    phoneNumbersEl = phoneNumbers.map((phoneNumber) => {
      if (!phoneNumber.number || phoneNumber.number === '') {
        isvPhoneLabel = isvPhoneLabel.replace('{{phoneNumber}}', '');
      } else {
        isvPhoneLabel = isvPhoneLabel.replace('{{phoneNumber}}', phoneNumber.number);
      }

      return (
        <Button
          variant={!isButton ? 'text' : 'secondary'}
          size="small"
          spaceStackEnd={!isButton ? 'm' : undefined}
          onClick={() =>
            trackCTAClick(
              trackingService,
              'call',
              phoneNumber.type,
              `${phoneNumber.number.replace(/\s/g, '')}`,
              `tel:${phoneNumber.number.replace(/\s/g, '')}`,
              relatedProduct
            )
          }
          href={`tel:${phoneNumber.number.replace(/\s/g, '')}`}
          spaceInlineEnd={!isButton ? 'xxl' : undefined}
          key={phoneNumber.type}
          data-testid={phoneNumber.type}
        >
          {vehicle.investorSharedVehicle ? (
            <>
              {isvLabel1} {isvPhoneLabel} {isvLabel2}{' '}
            </>
          ) : (
            <>
              {dealerPhoneLabel}: {phoneNumber.number}{' '}
            </>
          )}
          {showHotline && hotlineLabel}
        </Button>
      );
    });
  }

  return <>{phoneNumbersEl}</>;
};

export default DealerContact;
