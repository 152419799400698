import React, { useContext } from 'react';
import DealerContact from '../DealerContact';
import {
  CtaEditorConfig,
  displayByDealerId,
  getRelatedProduct,
  usePostData,
  VehicleBasic,
  isBevAgencyVehicle,
  isNationWideSellingVehicle,
} from '@oneaudi/vtp-shared';
import { SecondRow, StyledButtonGroup } from '../Tiles.styles';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import { GfaLayerManagerV1 } from '@volkswagen-onehub/gfa-layer-manager';
import { ResultTileLink } from '../ResultTileLink';
import { TileContext } from '../../hooks/tileHook';

interface CTALinksToBtnTemplateProps {
  hidePhone: boolean;
  vehicle: VehicleBasic;
  extendedLinks: CtaEditorConfig[];
  trackingService: TrackingServiceV2;
  layerManager: GfaLayerManagerV1;
}

export const CTALinksToBtnTemplate: React.FC<CTALinksToBtnTemplateProps> = ({
  vehicle,
  hidePhone,
  extendedLinks,
  trackingService,
  layerManager,
}) => {
  const relatedProduct = getRelatedProduct(vehicle);
  const postData = usePostData(vehicle);
  // On Ecom Cars show the Ecom Button unless the vehicle is reserved
  /* eslint-disable no-nested-ternary */
  const primaryCriteria = isNationWideSellingVehicle(vehicle)
    ? 'nws'
    : isBevAgencyVehicle(vehicle)
    ? 'bevAgency'
    : vehicle.buyableOnline
    ? !vehicle.reservation
      ? 'ecom'
      : 'contact'
    : 'contact';
  const primaryButton = extendedLinks.find((link) => link.type === primaryCriteria);
  const otherButtons = extendedLinks.filter((link) => link.type !== primaryCriteria);
  const isGridView = useContext(TileContext).viewType === 'grid';

  return (
    <StyledButtonGroup data-testid="spain-cta-section">
      {displayByDealerId(primaryButton!, vehicle.dealer.id) && (
        <ResultTileLink
          link={primaryButton!}
          trackingService={trackingService}
          relatedProduct={relatedProduct}
          postData={postData}
          layerManager={layerManager}
          isButton
          variant="primary"
        />
      )}

      <SecondRow isGridView={isGridView}>
        {otherButtons
          .filter((link) => displayByDealerId(link, vehicle.dealer.id))
          .map((link) => (
            <ResultTileLink
              link={link!}
              trackingService={trackingService}
              relatedProduct={relatedProduct}
              postData={postData}
              layerManager={layerManager}
              isButton
              variant="secondary"
            />
          ))}
        {!hidePhone && <DealerContact vehicle={vehicle} data-testid="phoneCTA" isButton />}
      </SecondRow>
    </StyledButtonGroup>
  );
};
