import { Button } from '@audi/audi-ui-react-v2';
import { useI18n } from '@oneaudi/i18n-context';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import { useServicesContext } from '@oneaudi/vtp-shared';
import React from 'react';
import { Container } from './CTA.styles';
import { trackDetailsPageNav } from './tracking';

interface Props {
  detailsPageLink: string;
}

const CTA: React.FC<Props> = ({ detailsPageLink }) => {
  const detailsPageLabel = useI18n({ id: 'nemo.ui.sc.tiles.detailspagebtn', defaultMessage: '' });
  const trackingService = useServicesContext().getAdditionalService(
    'audi-tracking-service'
  ) as TrackingServiceV2;

  if (!detailsPageLabel || detailsPageLabel.trim() === '') {
    return null;
  }

  return (
    <Container>
      <Button
        variant="primary"
        stretch
        onClick={() =>
          trackDetailsPageNav(trackingService, detailsPageLabel, detailsPageLink, 'button')
        }
        href={detailsPageLink}
        spaceStackEnd="xxs"
      >
        {detailsPageLabel}
      </Button>
    </Container>
  );
};

export default CTA;
