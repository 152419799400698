import { LayoutItem } from '@audi/audi-ui-react-v2';
import {
  VehicleBasic,
  useServicesContext,
  formatUrl,
  usePostData,
  getRelatedProduct,
  CtaEditorConfig,
  displayByDealerId,
  isBevAgencyVehicle,
  isNationWideSellingVehicle,
} from '@oneaudi/vtp-shared';
import React from 'react';
import { GfaLayerManagerV1 } from '@volkswagen-onehub/gfa-layer-manager';
import DealerContact from './DealerContact';
import { LayoutContainer } from './Tiles.styles';
import { useI18n } from '@oneaudi/i18n-context';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import { CTALinksToBtnTemplate } from './Markets/CTALinksToBtn';
import { ResultTileLink } from './ResultTileLink';
import { ConfigurationServiceV1 } from '@oneaudi/vtp-configuration-service';
import { getContentConfiguration } from '../hooks/tileHook';

interface Props {
  vehicle: VehicleBasic;
  detailsPageLink: string;
  showButtonCTAs?: boolean;
}

const CTALinks: React.FC<Props> = ({ vehicle, detailsPageLink, showButtonCTAs = false }) => {
  const contentConfiguration = getContentConfiguration();
  const configurationService = useServicesContext().getAdditionalService(
    'vtp-configuration-service'
  ) as ConfigurationServiceV1;

  // All CTAs configured using Configuration Service and FACE interface
  const vtpConfigCTAs = configurationService?.getConfiguration()?.cta
    ? (configurationService.getConfiguration()?.cta as CtaEditorConfig[])
    : [];
  const editorConfigCtas = contentConfiguration?.tile?.cta || [];
  const ctaConfigs = [...vtpConfigCTAs, ...editorConfigCtas];
  const linkMap = new Map(ctaConfigs.map((link: CtaEditorConfig) => [link.type, link]));
  const { scopes } = configurationService?.getConfiguration?.() || {};

  const ecomShowContact = scopes?.showEcomContact;
  const hideEcom = scopes?.hideEcom;

  // Logic for special CTAs which were previously configured in legacy AEM
  const specialCTATypes = ['contact', 'nws', 'ecom', 'leasing', 'bevAgency'];
  const bevAgencyVehicle = isBevAgencyVehicle(vehicle);
  const links: CtaEditorConfig[] = [];
  if (vehicle.financing && vehicle.leasingCar && linkMap.has('leasing')) {
    links.push(linkMap.get('leasing')!);
  } else if (vehicle.buyableOnline && !vehicle.reservation && !hideEcom && linkMap.has('ecom')) {
    if (ecomShowContact && linkMap.has('contact')) {
      links.push(linkMap.get('contact')!);
    }
    links.push(linkMap.get('ecom')!);
  } else if (isNationWideSellingVehicle(vehicle) && linkMap.has('nws')) {
    links.push(linkMap.get('nws')!);
  } else if (bevAgencyVehicle && linkMap.has('bevAgency')!) {
    links.push(linkMap.get('bevAgency')!);
  } else if (linkMap.has('contact')) {
    links.push(linkMap.get('contact')!);
  }

  let hidePhone = false;
  const hasCTAConfigFromFaceEditor = editorConfigCtas.length > 0;
  const hasCTAConfigFromConfigurationService = vtpConfigCTAs.length > 0;

  if (hasCTAConfigFromFaceEditor || hasCTAConfigFromConfigurationService) {
    for (const cta of ctaConfigs) {
      if (cta.type === 'availability-notification' && !vehicle.reservation) {
        continue; // eslint-disable-line
      }
      if (cta.type === 'reserve' && vehicle.reservation) {
        continue; // eslint-disable-line
      }
      if (cta.options?.replacePhone || cta.type === 'central-customer-hotline') {
        links.unshift(cta);
        hidePhone = true;
      } else if (!specialCTATypes.includes(cta.type!)) {
        links.push(cta);
      }
    }
  }

  const layerManager = useServicesContext().getAdditionalService(
    'gfa:layer-manager'
  ) as GfaLayerManagerV1;
  const postData = usePostData(vehicle);

  const relatedProduct = getRelatedProduct(vehicle);
  const trackingService = useServicesContext().getAdditionalService(
    'audi-tracking-service'
  ) as TrackingServiceV2;

  // const urlDomain = typeof window !== 'undefined' ? window.location.origin : '';
  const urlDomain = '';
  /* eslint-disable no-template-curly-in-string, prefer-template */
  const whatsAppMessagePrefix = useI18n({
    id: 'nemo.ui.sc.details.dealer-whatsapp.message',
    defaultMessage: '',
  })
    .replace('${commissionNumber}', vehicle.commissionNumber)
    .replace('${dealer}', vehicle.dealer.name || '');
  /* eslint-ensable */
  const whatsAppMessage = encodeURIComponent(
    `${whatsAppMessagePrefix} ${urlDomain}${detailsPageLink}`
  );

  const extendedLinks = links.map((link) => {
    const newLink: CtaEditorConfig = { ...link, url: link.url! };
    if (link.url) {
      if (link.url.includes('whatsapp')) {
        newLink.url = `${link.url}&text=${whatsAppMessage}`;
      } else {
        newLink.url = formatUrl(link.url, vehicle);
      }
    }
    return newLink;
  });
  // Move 'ecom' CTA to first position when available
  const ecomCTA = extendedLinks.find((item) => item.type === 'ecom');
  if (ecomCTA) {
    extendedLinks.splice(extendedLinks.indexOf(ecomCTA), 1);
    extendedLinks.unshift(ecomCTA!);
  }

  // render buttons ctas for spain [CSR-1085] & [CSR-1129]
  if (showButtonCTAs)
    return (
      <CTALinksToBtnTemplate
        vehicle={vehicle}
        hidePhone={hidePhone}
        extendedLinks={extendedLinks}
        trackingService={trackingService}
        layerManager={layerManager}
      />
    );
  return (
    <LayoutContainer spaceStackStart="m" direction="row" data-testid="cta-section">
      {!hidePhone && (
        <LayoutItem>
          <DealerContact vehicle={vehicle} data-testid="phoneCTA" />
        </LayoutItem>
      )}
      {extendedLinks
        .filter((link: CtaEditorConfig) => displayByDealerId(link, vehicle.dealer.id))
        .map((link, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <LayoutItem key={`${link.type}${index}`}>
            <ResultTileLink
              link={link!}
              trackingService={trackingService}
              relatedProduct={relatedProduct}
              postData={postData}
              layerManager={layerManager}
            />
          </LayoutItem>
        ))}
    </LayoutContainer>
  );
};

export default CTALinks;
