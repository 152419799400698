import { Text } from '@audi/audi-ui-react';
import { useI18n } from '@oneaudi/i18n-context';
import React from 'react';
import { getContentConfiguration } from '../hooks/tileHook';
import styled from 'styled-components';
import { FootnoteReference } from '@oneaudi/vtp-shared';

interface MatchingScoreProps {
  matchingScore: string;
}

export const MatchingScore: React.FC<MatchingScoreProps> = ({ matchingScore }) => {
  const matchingScoreText = useI18n({
    id: 'nemo.ui.sc.tiles.suggested.matchingScore',
    defaultMessage: 'Match',
  });
  const contentConfiguration = getContentConfiguration();
  const showMatchingScore =
    contentConfiguration?.suggestedCars.similarCars.matchmaker?.displayScore;
  return showMatchingScore ? (
    <MatchingScoreContainer className="matching-score-container">
      <Text data-testid="matching-score-display" variant="order4" as="span" spaceInlineEnd="xxs">
        {matchingScore}
      </Text>
      <StyledMatchingScoreText>
        {matchingScoreText}
        <FootnoteReference data-testid="matching-score-footnote" refId="fn_suggested_cars_score" />
      </StyledMatchingScoreText>
    </MatchingScoreContainer>
  ) : null;
};
const MatchingScoreContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: end;
`;
const StyledMatchingScoreText = styled((props) => (
  <Text data-testid="matching-score-label" variant="copy2" as="span" {...props} />
))`
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    padding-block-end: 2px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    padding-block-end: 3px;
  }
`;
