export const messages = {
  compare: {
    selectionHint: {
      defaultMessage: '{{count}} von {{maxCount}} im Vergleich',
      id: 'nemo.ui.sc.tiles.compare.selection.label',
    },
    compareNow: {
      defaultMessage: 'Jetzt vergleichen',
      id: 'nemo.ui.sc.tiles.compare.cta.label',
    },
    selectiom: {
      noSelectionHint: {
        defaultMessage: 'Bitte wählen Sie ein weiteres Modell zum Vergleich',
        id: 'nemo.ui.sc.tiles.compare.selection.no_selection.label',
      },
      remove: {
        defaultMessage: 'Entfernen',
        id: 'nemo.ui.sc.tiles.compare.selection.cta.remove.label',
      },
    },
  },
  similarVehiclesDisclaimer: {
    defaultMessage: '',
    id: 'nemo.ui.sc.tiles.suggested.disclaimer.similarVehicles',
  },
  lastVisitedVehiclesDisclaimer: {
    defaultMessage: '',
    id: 'nemo.ui.sc.tiles.suggested.disclaimer.lastVisitedVehicles',
  },
  similarDealerVehiclesDisclaimer: {
    defaultMessage: '',
    id: 'nemo.ui.sc.tiles.suggested.disclaimer.similarDealerVehicles',
  },
};
