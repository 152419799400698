import React from 'react';
import { CtaEditorConfig, openFocusLayerV2, ProductData } from '@oneaudi/vtp-shared';
import { Button } from '@audi/audi-ui-react-v2';
import { trackCTAClick } from './tracking';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import { GfaLayerManagerV1 } from '@volkswagen-onehub/gfa-layer-manager';

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'text'
  | 'icon-primary'
  | 'icon-secondary'
  | 'icon-tertiary';

interface ResultTileLinkProps {
  link: CtaEditorConfig;
  trackingService: TrackingServiceV2;
  relatedProduct: ProductData;
  postData: any;
  layerManager: GfaLayerManagerV1;
  isButton?: boolean;
  variant?: ButtonVariant;
}

export const ResultTileLink: React.FC<ResultTileLinkProps> = ({
  link,
  trackingService,
  relatedProduct,
  postData,
  layerManager,
  isButton = false,
  variant,
}) => {
  if (!link) return null;
  const hasMargin = !isButton;
  const buttonType = variant || 'text';

  return (
    <div data-testid="result-tile-link">
      {(link?.target === 'same-window' || link?.target === 'new-window') && (
        <>
          {link.method === 'POST' && (
            <form
              method="post"
              data-testid="form"
              action={link.url}
              target={link.target === 'new-window' ? '_blank' : '_self'}
            >
              <Button
                type="submit"
                variant={buttonType}
                spaceStackEnd={hasMargin ? 'm' : 'xxs'}
                size="small"
                stretch={isButton}
                spaceInlineEnd={hasMargin ? 'xxl' : 'xxs'}
                data-testid={`cta_${link.type}`}
                onClick={() =>
                  trackCTAClick(
                    trackingService,
                    'exit_link',
                    link?.type!,
                    link?.label!,
                    link.type === 'ecom' ? link.url : '',
                    relatedProduct
                  )
                }
              >
                {link?.label}
              </Button>
              <input type="hidden" name="inquiry_json_data" value={postData} />
            </form>
          )}
          {link.method === 'GET' && (
            <Button
              variant={buttonType}
              href={link.url}
              spaceStackEnd={hasMargin ? 'm' : undefined}
              spaceInlineEnd={hasMargin ? 'xxl' : undefined}
              size="small"
              stretch={isButton}
              newWindow={link?.target === 'new-window'}
              data-testid={`cta_${link.type}`}
              onClick={() =>
                trackCTAClick(
                  trackingService,
                  'exit_link',
                  link.type!,
                  link?.label!,
                  link.type === 'ecom' ? link.url : '',
                  relatedProduct
                )
              }
            >
              {link.label}
            </Button>
          )}
        </>
      )}
      {link.target === 'open-in-layer' && (
        <Button
          variant={buttonType}
          spaceStackEnd={hasMargin ? 'm' : undefined}
          spaceInlineEnd={hasMargin ? 'xxl' : undefined}
          size="small"
          stretch={isButton}
          onClick={() => {
            trackCTAClick(
              trackingService,
              'internal_link',
              link.type!,
              link.label!,
              '',
              relatedProduct
            );
            openFocusLayerV2(link, postData, layerManager);
          }}
          data-testid={`cta_${link.type}`}
        >
          {link.label}
        </Button>
      )}
    </div>
  );
};
